export type Token = string

const ApiUrl = 'https://tm.easykassa.ru/evotor/api'

export async function getQRToken(token: Token) {
  const URL = `${ApiUrl}/mobile/qr`
  const options = {
    method: 'GET',
    headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' },
  }

  return fetch(URL, options).then(response => response.json())
}

export async function getList(token: Token) {
  const URL = `${ApiUrl}/mobile`
  const options = {
    method: 'GET',
    headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' },
  }

  return fetch(URL, options).then(response => response.json())
}

export async function setDeviceAccess(token: Token, deviceId: string, action: 'disable' | 'enable') {
  // action = "disable" для выключения; любое другое значение чтобы включить
  const URL = `${ApiUrl}/mobile`
  const msg = { deviceId: deviceId, action: action }
  const options = {
    method: 'PATCH',
    headers: { Authorization: 'Bearer ' + token, 'Content-Type': 'application/json' },
    body: JSON.stringify(msg),
  }

  return fetch(URL, options).then(response => response.json())
}
