import React from 'react'

// @ts-ignore
import appstoreSvg from './appstore/badge_appstore-lrg.svg'
// @ts-ignore
import googlePlaySvg from './googleplay/Get_it_on_Google_play.svg'
// @ts-ignore
import rustoreSvg from './rustore/Rustore-2colors.svg'
// @ts-ignore
import huaweistoreSvg from './appgallery/market-app-gallery-black.svg'

const imageLinks = {
  ios: appstoreSvg,
  android: googlePlaySvg,
  rustore: rustoreSvg,
  huaweistore: huaweistoreSvg,
}

type Props = {
  store: 'ios' | 'android' | 'rustore' | 'huaweistore'
  url: string
  height?: number
  width?: number
  linkProps: object
}

const MobileStoreButton: React.FC<Props> = ({ store, url, height = 75, width = 255, linkProps, ...props }) => {
  const linkStyles = {
    background: `url(${imageLinks[store]}) no-repeat`,
    backgroundSize: 'contain',
    display: 'inline-block',
    overflow: 'hidden',
    textDecoration: 'none',
    height: '100%',
    width: '100%',
  }

  return (
    <div style={{ height, width, display: 'inline-block' }} {...props}>
      <a style={linkStyles} href={url} target="_blank" rel="noopener noreferrer" {...linkProps}>
        &nbsp;
      </a>
    </div>
  )
}

export default MobileStoreButton
