import React from 'react'
import { Space } from 'antd'

import { PlayMarketButton } from '../components/MobileStoreLinks/googleplay/PlayMarketButton'
import { AppStoreButton } from 'src/components/MobileStoreLinks/appstore/AppStoreButton'
import { RuStoreButton } from 'src/components/MobileStoreLinks/rustore/RuStoreButton'
import { HuaweiStoreButton } from 'src/components/MobileStoreLinks/appgallery/HuaweiStoreButton'

const DownloadApps: React.FC = () => {
  return (
    <>
      <Space size="large" style={{ marginLeft: '2rem' }}>
        <AppStoreButton />
        <PlayMarketButton />
        <RuStoreButton />
        <HuaweiStoreButton />
      </Space>
      <br />
    </>
  )
}

export default DownloadApps
