import React from 'react'
import Typography from 'antd/lib/typography'

const { Paragraph } = Typography

export const AuthNew: React.FC = () => {
  return (
    <Paragraph>
      <ul>
        <li>Запустите мобильное приложение</li>
        <li>Нажмите "Вход"</li>
        <li>Выберите "Эвотор"</li>
        <li>Авторизуйтесь в Личном кабинете Эвотор</li>
        <li>Нажмите "Войти"</li>
      </ul>
    </Paragraph>
  )
}
