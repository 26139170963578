import React from 'react'
import MobileStoreButton from '../MobileStoreButton'
import { PLAYMARKET_URL } from 'src/const'

export const PlayMarketButton = () => {
  return (
    <MobileStoreButton
      store="android"
      url={PLAYMARKET_URL}
      width={135}
      height={40}
      linkProps={{ title: 'Статистика торговли для Android в Google PlayMarket' }}
    />
  )
}
