import React from 'react'
import { AppProvider } from './components/AppContext'
import { PageInstallation } from 'src/PageInstallation/PageInstallation'

const App: React.FC = () => {
  return (
    <AppProvider>
      <PageInstallation />
    </AppProvider>
  )
}

export default App
