import React, {useEffect, useMemo, useState} from 'react'
import qs from 'query-string'
import { Token } from 'src/api'

type AppContextType = {
  evoToken: Token | null
}
export const AppContext = React.createContext<AppContextType>({ evoToken: null })

type Props = {
  children: JSX.Element
}

export const AppProvider: React.FC<Props> = ({ children }) => {
  const ev = useMemo(() => {
    const getParams = qs.parse(window.location.search)
    return getParams?.token as string ?? null
  }, [])

  const [evoToken, setEvoToken] = useState<Token | null>(ev)

  useEffect(() => {
    const getParams = qs.parse(window.location.search)
    const ev = getParams.token
    // @ts-ignore
    setEvoToken(ev)
  }, [])

  return <AppContext.Provider value={{ evoToken: evoToken }}>{children}</AppContext.Provider>
}
