import React, { useState } from 'react'
import { Divider } from 'antd/lib'
import Button from 'antd/lib/button'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import Typography from 'antd/lib/typography'

import DownloadApps from 'src/PageInstallation/DownloadApps'
import { AuthNew } from 'src/PageInstallation/AuthNew'
import { AuthOld } from 'src/PageInstallation/AuthOld'

const { Title, Paragraph } = Typography

export const PageInstallation: React.FC = () => {
  const [expandDevices, setExpandDevices] = useState<boolean>(false)

  const switchValue = () => {
    setExpandDevices(!expandDevices)
  }

  return (
    <>
      <Title level={3}>Подключение нового устройства</Title>
      <Title level={4}>1. Установите мобильное приложение</Title>
      <DownloadApps />
      <Title level={4}>2. Авторизуйте приложение</Title>
      <AuthNew />

      <Divider />
      <Paragraph style={{ maxWidth: '800px' }}>
        * Описанный выше способ подходит для новой версии приложения (версия 1.0.43 и выше). Временно мы оставили возможность
        подключить старые версии приложения. Рекомендуем установить новую версию.
      </Paragraph>

      <Button type="link" onClick={() => switchValue()}>
        Подключить устаревшую версию ({'<'}1.30) {expandDevices ? <UpOutlined /> : <DownOutlined />}
      </Button>
      <div style={{ display: expandDevices ? 'block' : 'none' }}>
        <AuthOld />
        {/*<DevicesList />*/}
      </div>
    </>
  )
}
