import React from 'react'
import MobileStoreButton from 'src/components/MobileStoreLinks/MobileStoreButton'
import { APPSTORE_URL } from 'src/const'

export const AppStoreButton = () => {
  return (
    <MobileStoreButton
      store="ios"
      url={APPSTORE_URL}
      width={135}
      height={40}
      linkProps={{ title: 'Статистика торговли для iOS в Apple AppStore' }}
    />
  )
}
