import React from 'react'
import MobileStoreButton from 'src/components/MobileStoreLinks/MobileStoreButton'
import { HuaweiStoreUrl } from 'src/const'

export const HuaweiStoreButton = () => {
  return (
    <MobileStoreButton
      store="huaweistore"
      url={HuaweiStoreUrl}
      width={135}
      height={40}
      linkProps={{ title: 'Статистика торговли для Android в AppGallery' }}
    />
  )
}
