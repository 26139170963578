import React from 'react'
import MobileStoreButton from 'src/components/MobileStoreLinks/MobileStoreButton'
import { RustoreUrl } from 'src/const'

export const RuStoreButton = () => {
  return (
    <MobileStoreButton
      store="rustore"
      url={RustoreUrl}
      width={121}
      height={40}
      linkProps={{ title: 'Статистика торговли для Android в RuStore' }}
    />
  )
}
