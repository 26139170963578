import Button from 'antd/lib/button'
import { QRCode } from 'react-qr-svg'
import dayjs from 'dayjs'
import { ReloadOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { AppContext } from 'src/components/AppContext'
import { DEEPLINK_AUTH } from 'src/const'
import { getQRToken } from 'src/api'
import Typography from 'antd/lib/typography'

const { Paragraph } = Typography

export const AuthOld: React.FC = () => {
  const { evoToken } = React.useContext(AppContext)
  const [qrToken, setQrToken] = useState()
  const [expireDt, setExpireDt] = useState<Date | null>()

  const authUrl = `${DEEPLINK_AUTH}${qrToken}`

  const onGenerateNew = () => {
    if (!evoToken) {
      return
    }
    getQRToken(evoToken).then(res => {
      const { token, expireDt } = res
      setQrToken(token)
      setExpireDt(dayjs(expireDt).toDate())
    })
  }

  useEffect(() => {
    if (!evoToken) {
      return
    }
    onGenerateNew()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evoToken])

  if (!qrToken) {
    return <>Неверный токен</>
  }

  return (
    <div style={{ marginLeft: '2rem' }}>
      а. При просмотре с мобильного устройства нажмите кнопку{' '}
      <Button href={authUrl} type={'primary'}>
        Авторизация
      </Button>{' '} или перейдите по ссылке
      <Paragraph style={{ marginBottom: 'unset', display: 'unset' }} copyable={{ text: authUrl }} />
      <div style={{ marginTop: 12 }}>б. Или просканируйте мобильным приложением QR-код</div>
      <QRCode
        bgColor="#FFFFFF"
        fgColor="#000000"
        level="Q"
        style={{ width: 250, marginBottom: '1rem', marginTop: '1rem', marginLeft: '2rem', display: 'block' }}
        value={qrToken}
      />
      <span>Код и ссылка действительны до {dayjs(expireDt).format('HH:mm DD MMMM YYYY')}</span>{' '}
      <Button size="small" onClick={onGenerateNew} style={{ marginBottom: 12, marginTop: 12 }}>
        <ReloadOutlined /> Создать новый код{' '}
      </Button>
    </div>
  )
}
